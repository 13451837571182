import React, { Fragment, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import Cookie from 'js-cookie';
import ClipLoader from 'react-spinners/ClipLoader';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import Content from '../../components/layout/Content';
import BottomNav from '../../components/layout/BottomNav';
import { OfferCard, Card } from '../../components/utils/Card';
import { SearchOffers } from '../../components/utils/SearchOffers';

const OfferList = () => {
    const [promotions, setPromotions] = useState([]);
    const [offers, setOffers] = useState([]);
    const [featured, setFeatured] = useState([]);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const { categoryId } = useParams();

    useEffect(() => {
        async function getPromotions() {
            try {
                setLoading(true);

                const { data } = await axios.get('/app_handler.php', { headers: { 'Wg-Method': 'LOAD_OFFERS', 'Wg-Key': Cookie.get('accessToken'), 'Wg-CategoryId': categoryId } });
            
                if (data) {
                    setPromotions(data?.offers);
                    setFeatured(data?.featured);
                    setCategory(data?.category?.category_name);
                }

                setLoading(false);

            } catch (error) {
                setLoading(false);
                console.error(error);
            }
        };

        async function getCategories() {
            setLoading(true);
            try {
                const { data } = await axios.get('/app_handler.php', { headers: { 'Wg-Method': 'RETURN_CATEGORIES', 'Wg-Key': Cookie.get('accessToken') } });

                if (data) {
                    setCategories(data.categories);
                }
                setLoading(false);

            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        getPromotions();
        getCategories();
    }, [categoryId]);

    const renderedPromotions = promotions ? (
        <div>
            <h2>{promotions.length} dun.deals found{category ? ` in ${category}` : ''}</h2>
            {promotions.map((promotion, i) => (
                <div key={promotion.primary_id} style={{ display: 'block', marginTop: i === 0 ? '0' : '1rem' }}>
                    <OfferCard size="S" offer={promotion} />
                </div>
            ))}
        </div>
    ) : (
        <div>Offers are not available</div>
    );

    const renderedCategories = categories.map((category, i) => (
        <Link key={category.category_id} to={`/student-offers/${category.category_id}`} style={{ display: 'block', marginTop: i === 0 ? '0' : '1rem' }}>
            <Card size="S">
                <div>{category.category_name}</div>
            </Card>
        </Link>
    ));

    const renderedOffers = offers ? (
        <div>
            <h2>{offers.length} dun.deals found for{searchTerm ? ` '${searchTerm}'` : ''}</h2>
            {offers.map((offer, i) => (
                <div key={offer?.offer_id} style={{ display: 'block', marginTop: i === 0 ? '0' : '1rem' }}>
                    <OfferCard size="S" offer={offer} />
                </div>
            ))}
        </div>
    ) : (
        <div>Offers are not available</div>
    );

    const renderedFeatured = featured ? (
        <div>
            {featured.map((promotion, i) => {
                const size = promotion?.field_value === '45' ? 'XL' : 'L';

                return (
                    <div key={promotion.primary_id} style={{ display: 'block', marginTop: i === 0 ? '0' : '1rem' }}>
                        <OfferCard size={size} color="#fff" offer={promotion} />
                    </div>
                );
            })}
        </div>
    ) : (
        <div>Offers are not available</div>
    );

    return (
        <Fragment>
            <Header />
            <Menu />
            <div style={{ marginTop: '76.27px' }}>
                <SearchOffers setLoading={setLoading} onFetch={setOffers} setTerm={setSearchTerm} />
            </div>
            <Content style={{ marginTop: 0 }}>
                {featured && (
                    <Fragment>
                        <h2>Featured</h2>
                        {renderedFeatured}
                    </Fragment>
                )}
                {offers.length > 0 ? (
                    <Fragment>
                        {!loading ? 
                            renderedOffers
                         : (
                            <div><ClipLoader color="#03d5e5" /></div>
                        )}
                    </Fragment>
                ) : (
                    <Fragment>
                        {!loading ? 
                            renderedPromotions
                         : (
                            <div><ClipLoader color="#03d5e5" /></div>
                        )}
                    </Fragment>
                )}
                <h2>Categories</h2>
                {renderedCategories}
            </Content>
            <BottomNav />
        </Fragment>
    )
}

export default OfferList;
